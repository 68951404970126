<template>
  <div class="loading">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { GET_LOGOUT_USER } from '../store/types';

export default {
  async mounted() {
    this.logoutuser();
    // try {
    //   this.$router.push('/login');
    // } catch (error) {
    //   console.log('error', error);
    //   this.$router.push('/login');
    // }
  },

  methods: {
    ...mapActions('users', [GET_LOGOUT_USER]),
    logoutuser() {
      setTimeout(() => { 
        this[GET_LOGOUT_USER]()
          .then(() => {
            this.$router.push("/login");
          })
      }, 2000);
    }
  },
};
</script>
