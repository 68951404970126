<template>
  <div class="w-100">
    <button
      type="button"
      data-toggle="modal"
      :data-target="'#uploadPhotos' + target"
      class="btn btn-secondary d-inline-block btn-full--sm"
      @click="uploadConf = {}"
    >
      {{ 'Upload Images' | translate }}
    </button>

    <div
      class="modal fade"
      tabindex="-1"
      :id="'uploadPhotos' + (target !== undefined ? 'h' : target)"
      role="dialog"
      :aria-labelledby="'uploadPhotos' + (target !== undefined ? 'h' : target)"
      aria-hidden="true"
    >
      <div
        v-if="!uploadConf.show"
        class="modal-dialog modal-dialog--edit"
        role="document"
      >
        <div class="modal-content edit-modal pt-0 px-0">
          <div class="modal-body my-0 p-3">
            <div id="file-drag-drop">
              <form
                class="my-photo-box d-flex align-items-center justify-content-center"
                v-on:drag.prevent.stop
                v-on:dragstart.prevent.stop
                v-on:dragend.prevent.stop
                v-on:dragover.prevent.stop
                v-on:dragenter.prevent.stop
                v-on:dragleave.prevent.stop
                v-on:drop.prevent.stop="processImages($event)"
              >
                <span>{{ 'Drop the files here!' | translate }}</span>
                <input
                  type="file"
                  id="files"
                  ref="files"
                  multiple
                  accept=".jpg, .png, .heif"
                  @change="handleFilesUpload()"
                />
              </form>
              <div class="alert alert-danger mt-3 mb-0" v-if="imageSizeWarning">
                <p>
                  {{
                    'One of your images is over the max size of 5MB and is not able to be uploaded.'
                      | translate
                  }}
                </p>
              </div>
              <div class="alert alert-danger mt-3 mb-0" v-if="imageFormatWarning">
                <p>
                  {{
                    'Only .jpg, .png, and .heif files are allowed.'
                      | translate
                  }}
                </p>
              </div>
              <div class="row">
                <div
                  v-for="(file, index) in files"
                  :key="index"
                  class="col-md-24 d-flex justify-content-between mt-3"
                >
                  <div class="d-flex flex-row">
                    <img
                      class="preview d-inline mr-2"
                      src="@/assets/icons/image-solid.svg"
                    />
                    <!-- :ref="'preview' + parseInt( index )" -->
                    <p class="d-inline pull-left mb-0">
                      <!-- mobile -->
                      <span
                        class="d-inline d-lg-none"
                        v-if="file.name.length > 19"
                      >
                        {{ file.name.substring(0, 19) }}...
                      </span>
                      <span class="d-inline d-lg-none" v-else>
                        {{ file.name }}
                      </span>
                      <!-- desktop -->
                      <span
                        class="d-none d-lg-inline"
                        v-if="file.name.length > 28"
                      >
                        {{ file.name.substring(0, 28) }}...
                      </span>
                      <span class="d-none d-lg-inline" v-else>
                        {{ file.name }}
                      </span>
                    </p>
                  </div>
                  <i
                    class="fa fa-times pull-right d-inline"
                    @click="removeFile(index)"
                  ></i>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <label
                    class="btn btn-secondary pull-left btn-full--sm"
                    for="files"
                    >{{ 'Browse' | translate }}</label
                  >
                </div>
                <div class="col-md-12">
                  <button
                    type="button"
                    class="btn btn-primary pull-right btn-full--sm mt-2 mt-md-0"
                    @click="() => submitFiles()"
                    :disabled="loader"
                  >
                    {{ 'Submit' | translate }}
                    <span v-if="loader === true" class="loader ml-1"></span>
                  </button>
                </div>
              </div>
              <div class="row d-flex justify-content-center">
                <button
                  type="button"
                  class="btn-cancel-modal mt-3"
                  data-dismiss="modal"
                >
                  {{ 'Cancel' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="uploadConf.show"
        class="modal-dialog modal-dialog--edit"
        role="document"
      >
        <div class="modal-content edit-modal p-3">
          <h2 class="h2 text-center">{{ uploadConf.header }}</h2>
          <div class="modal-body my-0">
            <p class="text-center mb-0">{{ uploadConf.body }}</p>
          </div>
          <div class="row d-flex justify-content-center">
            <button type="button" class="btn-cancel-modal" data-dismiss="modal">
              {{ 'Done' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="mediasCount > 0"
      class="d-flex flex-md-column d-md-inline align-items-center justify-content-center"
    >
      <a
        v-if="imageLibrary.length"
        @click="
          () => {
            populateMedias();
            initializedMedias = true;
          }
        "
        data-toggle="modal"
        data-target="#photoLibrary"
        class="view-images pt-2 pt-md-0 pl-0 pl-md-4"
      >
        {{ 'View Images' | translate }} ({{
          /* mediasCount */ imageLibrary.length
        }})
      </a>
    </div>

    <div
      class="modal fade"
      tabindex="-1"
      id="photoLibrary"
      role="dialog"
      aria-labelledby="photoLibrary"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content edit-modal p-3">
          <div class="loading" v-if="imageLibrary === null">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
          </div>
          <div v-else>
            <h2 v-if="imageLibrary.length === 1" class="h2 text-center pb-3">
              {{ 'Image Uploaded' | translate }}
            </h2>
            <h2 v-else class="h2 text-center pb-3">
              {{ 'Images Uploaded' | translate }}
            </h2>
            <div class="row d-flex justify-content-center">
              <div
                v-for="(image, index) in imageLibrary"
                :key="index"
                :class="['col-md-12', 'text-center', 'mb-3']"
              >
                <!-- {'mb-3' : index !== (imageLibrary.length-1)} -->
                <span class="icon-container" @click="deleteImage(image)">
                  <i class="fa fa-circle fa-stack-1x icon-a"></i>
                  <i class="fa fa-times-circle fa-stack-1x icon-b"></i>
                </span>

                <div
                  class="image d-flex justify-content-center align-items-center border-top border-right border-left"
                >
                  <img
                    class="view-image"
                    :src="
                      image.base64.startsWith('data:image') &&
                        dataURItoBlob(image.base64)
                    "
                  />
                </div>
                <button
                  class="btn btn-primary font-14 border bg-white py-2 w-100"
                  @click="imageLibrary[index].status = 'comment'"
                  v-show="image.status == 'add'"
                >
                  <span class="fa fa-plus fa-plus--comment mr-2"></span
                  >{{ 'Add Comment' | translate }}
                </button>
                <div
                  v-show="image.status == 'edit'"
                  class="bg-white py-3 px-2 mb-0 border-left border-right"
                >
                  <p class="font-14 mb-0 text-left">
                    {{ image.comment }}
                  </p>
                </div>
                <button
                  class="btn btn-primary font-14 border bg-white py-2 mb-2 w-100"
                  @click="image.status = 'comment'"
                  v-show="image.status == 'edit'"
                >
                  <span
                    class="fa fa-pencil-square-o fa-plus--comment mr-2"
                  ></span
                  >{{ 'Edit Comment' | translate }}
                </button>
                <div
                  id="commentContainer"
                  class="input-group"
                  v-show="image.status === 'comment'"
                >
                  <textarea
                    rows="4"
                    placeholder="write your comment here..."
                    maxlength="100"
                    class="form-control"
                    v-model="image.comment"
                  ></textarea>
                </div>
                <button
                  @click="commentOrAdd(index)"
                  v-show="image.status === 'comment'"
                  class="btn btn-primary w-100 mb-3"
                >
                  Done
                </button>
              </div>
              <p v-if="imageLibrary.length === 0">
                {{ 'You have no more images!' | translate }}
              </p>
            </div>
            <div class="row mx-0 d-flex">
              <div
                class="col-md-12 order-2 order-md-1 d-flex justify-content-center"
              >
                <button
                  class="btn-cancel-modal mt-2 mt-md-0"
                  data-dismiss="modal"
                >
                  {{ 'Cancel' | translate }}
                </button>
              </div>
              <div
                class="col-md-12 order-1 order-md-2 px-0 d-flex justify-content-center"
              >
                <button
                  type="button"
                  class="btn btn-primary btn-full--sm "
                  @click="editOrDeletePhotos"
                  data-dismiss="modal"
                  :disabled="loader"
                >
                  {{ 'Submit' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import {
  GET_CUSTOMER_BY_ID,
  GET_UPDATE_ASSESSOR,
  GET_UPDATE_ME,
  GET_UPDATE_USER,
  GET_UPLOAD_PROFILE_IMAGE,
  GET_USER_BY_ID,
} from '../../store/types';

import photosHandlingMixin from '@/mixins/photosHandlingMixin.js';
import imageUploadFn from '@/mixins/imageUploadFn.js';

export default {
  /*
      Variables used by the drag and drop component
    */
  mixins: [photosHandlingMixin, imageUploadFn],
  data() {
    return {
      initializedMedias: false,
      dragAndDropCapable: false,
      error: false,
      files: [],
      uploadPercentage: 0,
      imageSizeWarning: false,
      imageFormatWarning: false,
      images: [],
      deletedImages: [],
      imageLibrary: [],
      uploadConf: {},
      loader: false,
      group: null,
      maxWidth: 250,
      customer: null,
    };
  },

  props: ['assessorId', 'assessor', 'target'],

  computed: {
    ...mapState({
      evaluation: state => state.evaluations.evaluation,
      loggedInUser: state => state.users.user,
    }),
    user() {
      return this.customer || this.loggedInUser;
    },

    isAdmin() {
      console.log(this.$store);
      return this.$store.state.adminRoles.includes(this.user.role);
    },

    mediasCount: {
      cache: false,
      get: function() {
        if (this.assessor && this.assessor.data.medias) {
          return _.size(this.assessor.data.medias);
        } else {
          return 0;
        }
      },
    },
  },

  beforeDestroy() {
    this.customer = null;
  },

  async created() {
    if (this.loggedInUser._id !== this.$route.params.userId) {
      this.customer = await this[GET_CUSTOMER_BY_ID](this.$route.params.userId);
    }

    this.group = this.evaluation.groups[this.$route.params.groupId];
    this.populateMedias();
  },

  methods: {
    // map all the actions from store, into the components
    ...mapActions('users', [
      GET_UPLOAD_PROFILE_IMAGE,
      GET_UPDATE_ME,
      GET_USER_BY_ID,
      GET_CUSTOMER_BY_ID,
      GET_UPDATE_USER,
    ]),
    ...mapActions('assessors', [GET_UPDATE_ASSESSOR]),
    defaultImgMetaData() {
      return {
        id: this.$generateId(),
        comment: '',
        date_created: Date.now(),
        base64: '',
        width: 0,
        height: 0,
      };
    },
    processImages(e) {
  for (let i = 0; i < e.dataTransfer.files.length; i++) {
    const file = e.dataTransfer.files[i];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/heif'];
    const maxSizeMB = 5;
    
    if (!allowedTypes.includes(file.type)) {
      this.imageFormatWarning = true;
          setTimeout(()=> {
            this.imageFormatWarning = false
          },3000)
          return
    }
    
    if (file.size / 1024 / 1024 <= maxSizeMB) {
      this.files.push(file);
    } else {
      this.imageSizeWarning = true;
        setTimeout(()=> {
          this.imageSizeWarning = false
        },3000)
    }
  }
},

    determineDragAndDropCapable() {
      let div = document.createElement('div');
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      );
    },

    commentOrAdd(index) {
      if (this.imageLibrary[index].comment === '') {
        this.imageLibrary[index].status = 'add';
      } else {
        this.imageLibrary[index].status = 'edit';
      }
    },

    removeFile(key) {
      this.files.splice(key, 1);
    },

    async handleFilesUpload() {
      this.imageSizeWarning = false;
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/heif']; 
        // const file this.$refs.files.files[i];
        const file = this.$refs.files.files[i];
        if (!allowedTypes.includes(file.type)) {
          this.imageFormatWarning = true;
              setTimeout(()=> {
                this.imageFormatWarning = false
              },3000)
              return
        }
        if (this.$refs.files.files[i].size / 1024 / 1024 < 5) {
          this.files.push(file);
          const base64 = URL.createObjectURL(file);
        } else {
          this.imageSizeWarning = true;
          setTimeout(()=> {
            this.imageSizeWarning = false
          },3000)
        }
      }
    },

    calcHeight({ width, height }) {
      const aspectRatio = width / height;
      const calcHeight = this.maxWidth / aspectRatio;
      return calcHeight;
    },

    async uploadImgOnServer(file) {
      const params = new FormData();
      params.append('files', file);
      await this[GET_UPLOAD_PROFILE_IMAGE](params);
    },

    async getUploadedImgsURL() {
      // const formData = new FormData();
      // // for (let i = 0; i < this.files.length; i++) {
      // // formData.append('files[]', this.files[i]);
      // // }
      // this.files.forEach(file => {
      //   formData.append('files', file);
      // });
      // const urls = await this[GET_UPLOAD_PROFILE_IMAGE](formData);
      // return Array.isArray(urls) ? urls : [urls];
      // return new Promise((resolve, reject) => {
      return await Promise.all(
        this.files.map(async file => {
          const url = URL.createObjectURL(file);
          const {
            dataUrl,
            height,
            width,
          } = await this.getBase64WithWidthAndHeight(url);
          return {
            dataUrl,
            height,
            width,
          };
        })
      );
      // });
    },

    async submitFiles() {
      try {
        this.loader = true;
        // if previous assessor contain the array of imageURLs so we convert into the object.
        if (Array.isArray(this.assessor.data.medias)) {
          const oldMediasArray = this.assessor.data.medias;
          let newMediasObj = {};
          for (let i = 0; i < oldMediasArray.length; i++) {
            newMediasObj[oldMediasArray[i]] = oldMediasArray[i];
          }
          this.assessor.data.medias = newMediasObj;
        }

        if (!this.assessor.data.medias) {
          this.assessor.data.medias = {};
        }

        // here need to call the function which will return image base64, width, height and
        const imageURLs = await this.getUploadedImgsURL();
        console.log({ imageURLs });

        // for (const { URL: url, ...rest } of imageURLs) {
        for (const { dataUrl, height, width } of imageURLs) {
          // const { width, height } = await this.imageSize(url);
          this.imageLibrary.push({
            id: this.$generateId(),
            comment: '',
            date_created: Date.now(),
            width: width < this.maxWidth ? width : this.maxWidth,
            height: this.calcHeight({ width, height }),
            base64: dataUrl,
            status: 'add',
          });
        }

        // START update media in user object,
        const { userMedias, assessorMedias } = this.getMediasObject();

        // update the db with latest images object
        this.addMediaInUserAndAssessorMedias(userMedias, assessorMedias);
        const user = this.customer || this.user;
        this.$set(this.assessor.data, 'medias', assessorMedias);
        this.$set(user, 'medias', { ...userMedias, ...user.medias });

        this.uploadConf.show = true;
        this.uploadConf.header = this.$t('Upload Successful');
        this.uploadConf.body = this.$t(
          'Your photos have been successfully uploaded.'
        );
        this.$forceUpdate();

        setTimeout(() => {
          this.files = [];
          this.loader = false;
        }, 1000);
      } catch (error) {
        console.log(error);
        this.loader = false;
        this.uploadConf.show = true;
        this.uploadConf.header = this.$t('Upload Error');
        this.uploadConf.body = this.$t(
          'A problem occured when uploading your photos. Please try again'
        );
      }
    },

    getMediasObject() {
      const userMedias = {};
      const assessorMedias = {};
      for (let i = 0; i < this.imageLibrary.length; i++) {
        const media = this.imageLibrary[i];
        userMedias[media.id] = media;
        assessorMedias[media.id] = media.id;
      }

      return { userMedias, assessorMedias };
    },

    addMediaInUserAndAssessorMedias(userMedias, assessorMedias) {
      const updatedUser = {
        // ...this.user,
        ...(this.isAdmin ? { _id: this.evaluation.userId } : {}),
        medias: { ...(this.user.medias || {}), ...userMedias },
      };
      const updatedAssessor = {
        evaluationId: this.evaluation._id,
        groupId: this.group.id,
        assessor: {
          ...this.assessor,
          data: {
            ...this.assessor.data,
            medias: { ...(this.assessor.medias || {}), ...assessorMedias },
          },
        },
      };

      // add the id of media into assessor and actual media into user.
      // Promise.all([
      const UPDATE_USER = this.isAdmin ? GET_UPDATE_USER : GET_UPDATE_ME;
      this[UPDATE_USER](updatedUser)
      this[GET_UPDATE_ASSESSOR](updatedAssessor)
       
    },

    deleteImage(image) {
      this.deletedImages.push(
        this.imageLibrary.find(item => item.id == image.id).id
      );
      this.imageLibrary = this.imageLibrary.filter(
        item => item.id !== image.id
      );
    },

    populateMedias() {
      this.imageLibrary = [];
      const { medias } = this.assessor.data || {};
      let { medias: userMedia } = this.user || {};

      // Firstly we'll check we have data exist in both object (user and in assessor)
      if (!medias || !userMedia) return;

      // if data in the array so just simply get the data out from the array, so we always deal with object.
      if (userMedia.length) {
        userMedia = userMedia[0];
      }

      // gripping the current assessor medias form user media object.
      Object.keys(medias).forEach(mediaId => {
        const media = userMedia[mediaId];
        console.log(media);
        // only display the image if its is the base64 type image
        const isBase64Image = media && media.base64.startsWith('data:image');
        if (isBase64Image) this.imageLibrary.push(media);
      });
    },

    async setUpdateAndDelete({ userMedias, assessorMedias }) {
      const UPDATE_USER = this.isAdmin ? GET_UPDATE_USER : GET_UPDATE_ME;
      await Promise.all(
        this[UPDATE_USER]({
          // ...this.user,
          ...(this.isAdmin ? { _id: this.evaluation.userId } : {}),
          medias: userMedias,
        }),

        this[GET_UPDATE_ASSESSOR]({
          evaluationId: this.evaluation._id,
          groupId: this.group.id,
          assessor: {
            ...this.assessor,
            data: {
              ...this.assessor.data,
              medias: assessorMedias,
            },
          },
        })
      );
    },

    async deletePhotos() {
      let { medias: assessorMedias = {} } = this.assessor.data || {};
      let { medias: userMedias = {} } = this.user || {};
      this.deletedImages.forEach(mediaId => {
        if (userMedias[mediaId] || assessorMedias[mediaId]) {
          userMedias[mediaId] && delete userMedias[mediaId];
          assessorMedias[mediaId] && delete assessorMedias[mediaId];
        }
      });

      // update both user and assessor object with the remaining photos
      this.setUpdateAndDelete({ userMedias, assessorMedias });
      this.$set(this.assessor.data, 'medias', assessorMedias);
      this.$set(this.user, 'medias', userMedias);
      this.$forceUpdate();
      this.deletedImages = [];
    },

    async editOrDeletePhotos() {
      // delete images
      if (this.deletedImages.length) {
        return await this.deletePhotos(this.deletedImages);
      }

      const { userMedias, assessorMedias } = this.getMediasObject();

      // update the db with latest images object
      this.addMediaInUserAndAssessorMedias(userMedias, assessorMedias);
      this.$forceUpdate();

      // this.populateMedias();
    },
  },
};
</script>
