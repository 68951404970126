<template>
    <div class="container main-container main-container--hygiene view">
         <div class="row">
             <div class="col-lg-24">
                 <h2 class="h2 d-inline-block">{{'Summary' | translate}}</h2>
             </div>
         </div>
         <hr class="mb-lg-4">
 
         <div class="row table-wrapper">
             <h2 class="h2 d-inline-block mt-4">{{ 'Transition Performance Parameters' | translate }}</h2>
             <table class="table primary">
                 <tr>
                     <th scope="col" rowspan="2" class="index-col text-left">{{ 'Index' | translate }}</th>
                     <th scope="col" colspan="5">{{ 'Industry Percentile' | translate }}</th>
                 </tr>
                 <tr>
                     <th scope="col">{{ '10th' | translate }}</th>
                     <th scope="col">{{ '25th' | translate }}</th>
                     <th scope="col">{{ '50th' | translate }}</th>
                     <th scope="col">{{ '75th' | translate }}</th>
                     <th scope="col">{{ '90th' | translate }}</th>
                 </tr>
                 <tr class="heading">
                     <td>{{ 'Projected First Test 305-Day Milk' | translate }} ({{ $getEquivalentUnit(evaluation.data.measurements,'kg') | translate }})</td>
                     <td class="text-center">{{
                         parseInt(convert(transitionConstants['projected_first_test_305_min'])
                         .from('kg')
                         .to(this.$getEquivalentUnit(this.evaluation.data.measurements,'kg'))).toDecimal(0)
                         }}</td>
                     <td></td>
                     <td class="text-center">{{
                         parseInt(convert(transitionConstants['projected_first_test_305_mid'])
                         .from('kg')
                         .to(this.$getEquivalentUnit(this.evaluation.data.measurements,'kg'))).toDecimal(0)
                         }}</td>
                     <td></td>
                     <td class="text-center">{{
                         parseInt(convert(transitionConstants['projected_first_test_305_max'])
                         .from('kg')
                         .to(this.$getEquivalentUnit(this.evaluation.data.measurements,'kg'))).toDecimal(0)
                         }}</td>
                 </tr>
                 <tr>
                     <td>{{ this.$getEquivalentUnit(this.evaluation.data.measurements,'kg') | translate }}</td>
                     <td class="slider-value" colspan="5">
                         <span :style="`left: ${getSliderValuePosition('projected_first_test_305')}`"
                               :class="getSliderValueFill('projected_first_test_305')"
                         >
                             {{ evaluation.data['projected_first_test_305_' + this.$getEquivalentUnit(this.evaluation.data.measurements,'kg')] }}
                         </span>
                     </td>
                 </tr>
                 <tr class="heading">
                     <td>{{ 'Peak Ratio' | translate }} %</td>
                     <td class="text-center">>{{ transitionConstants['peak_ratio_min'] }}%</td>
                     <td></td>
                     <td class="text-center">{{ transitionConstants['peak_ratio_mid'] }}%</td>
                     <td></td>
                     <td class="text-center">>{{ transitionConstants['peak_ratio_max'] }}%</td>
                 </tr>
                 <tr>
                     <td>{{ 'Ratio of 1st to ≥ 2nd Lactation Milk Production Peaks (%)' | translate }}</td>
                     <td class="slider-value" colspan="5">
                         <span :style="`left: ${getSliderValuePosition('peak_ratio')}`"
                               :class="getSliderValueFill('peak_ratio')"
                         >
                             {{  evaluation.data.peak_ratio }}%
                         </span>
                     </td>
                 </tr>
                 <tr class="heading">
                     <td>{{ '% First Test Fat-Protein Ratio' | translate }}</td>
                     <td class="text-center">>{{ transitionConstants['first_test_fat_protein_ratio_1_max'] }}%</td>
                     <td></td>
                     <td class="text-center">{{ transitionConstants['first_test_fat_protein_ratio_1_mid'] }}%</td>
                     <td></td>
                     <td class="text-center">
                         &lt;{{ transitionConstants['first_test_fat_protein_ratio_1_min'] }}%
                     </td>
                 </tr>
                 <tr>
                     <td>{{ '1st Lactation, % >1.4' | translate }}</td>
                     <td class="slider-value" colspan="5">
                         <span :style="`left: ${getSliderValuePosition('first_test_fat_protein_ratio_1', 'rtl')}`"
                               :class="getSliderValueFill('first_test_fat_protein_ratio_1', 'rtl')"
                         >
                             {{  evaluation.data.first_test_fat_protein_ratio_1 }}%
                         </span>
                     </td>
                 </tr>
                 <tr>
                     <td>{{ '≥ 2nd Lactation, % >1.4' | translate }}</td>
                     <td class="slider-value" colspan="5">
                         <span :style="`left: ${getSliderValuePosition('first_test_fat_protein_ratio_2', 'rtl')}`"
                               :class="getSliderValueFill('first_test_fat_protein_ratio_2', 'rtl')"
                         >
                             {{  evaluation.data.first_test_fat_protein_ratio_2 }}%
                         </span>
                     </td>
                 </tr>
                 <tr class="heading">
                     <td>{{ 'SCC at First Test (5 to 40 DIM)' | translate }}</td>
                     <td class="text-center">>{{ transitionConstants['scc_at_first_test_1_max'] }}%</td>
                     <td></td>
                     <td class="text-center">{{ transitionConstants['scc_at_first_test_1_mid'] }}%</td>
                     <td></td>
                     <td class="text-center">
                         &lt;{{ transitionConstants['scc_at_first_test_1_min'] }}%
                     </td>
                 </tr>
                 <tr>
                     <td>{{ '1st Lactation, % ≥200,000' | translate }}</td>
                     <td class="slider-value" colspan="5">
                         <span :style="`left: ${getSliderValuePosition('scc_at_first_test_1', 'rtl')}`"
                               :class="getSliderValueFill('scc_at_first_test_1', 'rtl')"
                         >
                             {{  evaluation.data.scc_at_first_test_1 }}%
                         </span>
                     </td>
                 </tr>
                 <tr>
                     <td>{{ '≥ 2nd Lactation, % ≥200,000' | translate }}</td>
                     <td class="slider-value" colspan="5">
                         <span :style="`left: ${getSliderValuePosition('scc_at_first_test_2', 'rtl')}`"
                               :class="getSliderValueFill('scc_at_first_test_2', 'rtl')"
                         >
                             {{  evaluation.data.scc_at_first_test_2 }}%
                         </span>
                     </td>
                 </tr>
                 <tr class="heading">
                     <td>{{ '% Stillbirths, 1st Lactation' | translate }}</td>
                     <td class="text-center">>{{ transitionConstants['stillbirths_1_max'] }}%</td>
                     <td></td>
                     <td class="text-center">{{ transitionConstants['stillbirths_1_mid'] }}%</td>
                     <td></td>
                     <td class="text-center">
                         &lt;{{ transitionConstants['stillbirths_1_min'] }}%
                     </td>
                 </tr>
                 <tr>
                     <td>{{ '% Stillbirths, 1st Lactation' | translate }}</td>
                     <td class="slider-value" colspan="5">
                         <span :style="`left: ${getSliderValuePosition('stillbirths_1', 'rtl')}`"
                               :class="getSliderValueFill('stillbirths_1', 'rtl')"
                         >
                             {{  evaluation.data.stillbirths_1 }}%
                         </span>
                     </td>
                 </tr>
                 <tr class="heading">
                     <td>{{ '% Stillbirths, ≥ 2nd Lactation' | translate }}</td>
                     <td class="text-center">>{{ transitionConstants['stillbirths_2_max'] }}%</td>
                     <td></td>
                     <td class="text-center">{{ transitionConstants['stillbirths_2_mid'] }}%</td>
                     <td></td>
                     <td class="text-center">
                         &lt;{{ transitionConstants['stillbirths_2_min'] }}%
                     </td>
                 </tr>
                 <tr>
                     <td>{{ '% Stillbirths, ≥ 2nd Lactation' | translate }}</td>
                     <td class="slider-value" colspan="5">
                         <span :style="`left: ${getSliderValuePosition('stillbirths_2', 'rtl')}`"
                               :class="getSliderValueFill('stillbirths_2', 'rtl')"
                         >
                             {{  evaluation.data.stillbirths_2 }}%
                         </span>
                     </td>
                 </tr>
                 <tr class="heading">
                     <td>{{ 'Dry Period "Cure" Rate' | translate }}</td>
                     <td class="text-center">
                         &lt;{{ transitionConstants['dry_period_cure_rate_min'] }}%
                     </td>
                     <td></td>
                     <td class="text-center">{{ transitionConstants['dry_period_cure_rate_mid'] }}%</td>
                     <td></td>
                     <td class="text-center">>{{ transitionConstants['dry_period_cure_rate_max'] }}%</td>
                 </tr>
                 <tr>
                     <td>
                         {{ '% Cows ≥200,000 at Dry Off, < 200,000 at 1st Test' | translate }}
                     </td>
                     <td class="slider-value" colspan="5">
                         <span :style="`left: ${getSliderValuePosition('dry_period_cure_rate')}`"
                               :class="getSliderValueFill('dry_period_cure_rate')"
                         >
                             {{  evaluation.data.dry_period_cure_rate }}%
                         </span>
                     </td>
                 </tr>
                 <tr class="heading">
                     <td>{{ 'Turnover % (Sold + Died) ≤ 60 DIM' | translate }}</td>
                     <td class="text-center">>{{ transitionConstants['turnover_sold_died_max'] }}%</td>
                     <td></td>
                     <td class="text-center">{{ transitionConstants['turnover_sold_died_mid'] }}%</td>
                     <td></td>
                     <td class="text-center">
                         &lt;{{ transitionConstants['turnover_sold_died_min'] }}%
                     </td>
                 </tr>
                 <tr>
                     <td>{{ 'Turnover % (Sold + Died) ≤ 60 DIM' | translate }}</td>
                     <td class="slider-value" colspan="5">
                         <span :style="`left: ${getSliderValuePosition('turnover_sold_died', 'rtl')}`"
                               :class="getSliderValueFill('turnover_sold_died', 'rtl')"
                         >
                             {{  evaluation.data.turnover_sold_died }}%
                         </span>
                     </td>
                 </tr>
                 <tr class="heading">
                     <td>{{ '% Death Loss, ≤ 60 DIM' | translate }}</td>
                     <td class="text-center">>{{ transitionConstants['deaths_max'] }}%</td>
                     <td></td>
                     <td class="text-center">{{ transitionConstants['deaths_mid'] }}%</td>
                     <td></td>
                     <td class="text-center">
                         &lt;{{ transitionConstants['deaths_min'] }}%
                     </td>
                 </tr>
                 <tr>
                     <td>{{ '% Death Loss, ≤ 60 DIM' | translate }}</td>
                     <td class="slider-value" colspan="5">
                         <span :style="`left: ${getSliderValuePosition('deaths', 'rtl')}`"
                               :class="getSliderValueFill('deaths', 'rtl')"
                         >
                             {{  evaluation.data.deaths }}%
                         </span>
                     </td>
                 </tr>
             </table>
             <h2 class="h2 d-inline-block mt-4">{{ 'Disease Rate (%)' | translate }}</h2>
             <table class="table primary">
                 <tr>
                     <th scope="col" rowspan="2" class="index-col text-left">{{ 'Disease' | translate }}</th>
                     <th scope="col" colspan="5">{{ 'Industry Percentile' | translate }}</th>
                 </tr>
                 <tr>
                     <th scope="col">{{ '10th' | translate }}</th>
                     <th scope="col">{{ '25th' | translate }}</th>
                     <th scope="col">{{ '50th' | translate }}</th>
                     <th scope="col">{{ '75th' | translate }}</th>
                     <th scope="col">{{ '90th' | translate }}</th> 
                 </tr>
                 <tr class="heading">
                     <td>{{ 'Clinical Milk Fever (for lact ≥2 only)' | translate }}</td>
                     <td class="text-center">>{{ transitionConstants['clinical_milk_fever_max'] }}%</td>
                     <td></td>
                     <td class="text-center">{{ transitionConstants['clinical_milk_fever_mid'] }}%</td>
                     <td></td>
                     <td class="text-center">
                         &lt;{{ transitionConstants['clinical_milk_fever_min'] }}%
                     </td>
                 </tr>
                 <tr>
                     <td>{{ 'Herd clinical milk fever rate (cases in 2+ lact in last yr/2+ lact fresh in last yr)' | translate }}</td>
                     <td class="slider-value" colspan="5">
                         <span :style="`left: ${getSliderValuePosition('clinical_milk_fever', 'rtl')}`"
                               :class="getSliderValueFill('clinical_milk_fever', 'slider')"
                         >
                             {{  evaluation.data.clinical_milk_fever }}%
                         </span>
                     </td>
                 </tr>
                 <tr class="heading">
                     <td>{{ 'Retained Placenta' | translate }}</td>
                     <td class="text-center">>{{ transitionConstants['retained_placenta_max'] }}%</td>
                     <td></td>
                     <td class="text-center">{{ transitionConstants['retained_placenta_mid'] }}%</td>
                     <td></td>
                     <td class="text-center">
                         &lt;{{ transitionConstants['retained_placenta_min'] }}%
                     </td>
                 </tr>
                 <tr>
                     <td>{{ 'Herd retained placenta rate (cases in last yr / fresh in last yr)' | translate }}</td>
                     <td class="slider-value" colspan="5">
                         <span :style="`left: ${getSliderValuePosition('retained_placenta', 'rtl')}`"
                               :class="getSliderValueFill('retained_placenta', 'slider')"
                         >
                             {{  evaluation.data.retained_placenta }}%
                         </span>
                     </td>
                 </tr>
                 <tr class="heading">
                     <td>{{ 'Metritis' | translate }}</td>
                     <td class="text-center">>{{ transitionConstants['metritis_max'] }}%</td>
                     <td></td>
                     <td class="text-center">{{ transitionConstants['metritis_mid'] }}%</td>
                     <td></td>
                     <td class="text-center">
                         &lt;{{ transitionConstants['metritis_min'] }}%
                     </td>
                 </tr>
                 <tr>
                     <td>{{ 'Herd metritis rate (cases in last yr / fresh in last yr)' | translate }}</td>
                     <td class="slider-value" colspan="5">
                         <span :style="`left: ${getSliderValuePosition('metritis', 'rtl')}`"
                               :class="getSliderValueFill('metritis', 'slider')"
                         >
                             {{  evaluation.data.metritis }}%
                         </span>
                     </td>
                 </tr>
                 <tr class="heading">
                     <td>{{ 'Displaced Abomasum' | translate }}</td>
                     <td class="text-center">>{{ transitionConstants['displaced_abomasum_max'] }}%</td>
                     <td></td>
                     <td class="text-center">{{ transitionConstants['displaced_abomasum_mid'] }}%</td>
                     <td></td>
                     <td class="text-center">
                         &lt;{{ transitionConstants['displaced_abomasum_min'] }}%
                     </td>
                 </tr>
                 <tr>
                     <td>{{ 'Herd displaced abomasum rate (cases ≤ 60 DIM in last year/fresh in last year)' | translate }}</td>
                     <td class="slider-value" colspan="5">
                         <span :style="`left: ${getSliderValuePosition('displaced_abomasum', 'rtl')}`"
                               :class="getSliderValueFill('displaced_abomasum', 'slider')"
                         >
                             {{  evaluation.data.displaced_abomasum }}%
                         </span>
                     </td>
                 </tr>
                 <tr class="heading">
                     <td>{{ 'Pneumonia' | translate }}</td>
                     <td class="text-center">>{{ transitionConstants['pneumonia_max'] }}%</td>
                     <td></td>
                     <td class="text-center">{{ transitionConstants['pneumonia_mid'] }}%</td>
                     <td></td>
                     <td class="text-center">&lt;{{ transitionConstants['pneumonia_min'] }}%</td>
                 </tr>
                 <tr>
                     <td>{{ 'Herd Pneumonia rate (cases in last year/fresh in last year)' | translate }}</td>
                     <td class="slider-value" colspan="5">
                         <span :style="`left: ${getSliderValuePosition('pneumonia', 'rtl')}`"
                               :class="getSliderValueFill('pneumonia', 'slider')"
                         >
                             {{  evaluation.data.pneumonia }}%
                         </span>
                     </td>
                 </tr>
                 <tr class="heading">
                     <td>{{ 'Enteritis' | translate }}</td>
                     <td class="text-center">>{{ transitionConstants['enteritis_max'] }}%</td>
                     <td></td>
                     <td class="text-center">{{ transitionConstants['enteritis_mid'] }}%</td>
                     <td></td>
                     <td class="text-center">
                         &lt;{{ transitionConstants['enteritis_min'] }}%
                     </td>
                 </tr>
                 <tr>
                     <td>{{ 'Herd Enteritis rate (cases ≤ 60 DIM in last year/fresh in last year)' | translate }}</td>
                     <td class="slider-value" colspan="5">
                         <span :style="`left: ${getSliderValuePosition('enteritis', 'rtl')}`"
                               :class="getSliderValueFill('enteritis', 'slider')"
                         >
                             {{  evaluation.data.enteritis }}%
                         </span>
                     </td>
                 </tr>
                 <tr class="heading">
                     <td>{{ 'Ketosis (assuming intense detection)' | translate }}</td>
                     <td class="text-center">>{{ transitionConstants['ketosis_max'] }}%</td>
                     <td></td>
                     <td class="text-center">{{ transitionConstants['ketosis_mid'] }}%</td>
                     <td></td>
                     <td class="text-center">
                         &lt;{{ transitionConstants['ketosis_min'] }}%
                     </td>
                 </tr>
                 <tr>
                     <td>
                         {{ 'Herd ketosis rate (cases' | translate }}&nbsp;&le;
                         {{ '60 DIM in last year/fresh in last year) assuming intense surveillance' | translate }}
                     </td>
                     <td class="slider-value" colspan="5">
                         <span :style="`left: ${getSliderValuePosition('ketosis', 'rtl')}`"
                               :class="getSliderValueFill('ketosis', 'slider')"
                         >
                             {{  evaluation.data.ketosis }}%
                         </span>
                     </td>
                 </tr>
             </table>
             <h2 class="h2 d-inline-block mt-4">{{ 'Facilities & Management' | translate }}</h2>
             <table class="table primary table-5-col">
                 <tr>
                     <th scope="col" class="text-left col-width">{{ 'Estimated Risk' | translate }}</th>
                     <th scope="col" class="col-width">{{ 'High Risk' | translate }}</th>
                     <th scope="col" class="col-width">{{ 'Moderate Risk' | translate }}</th>
                     <th scope="col" class="col-width">{{ 'Low Risk' | translate }}</th>
                     <th scope="col" class="col-width">{{ 'Comments' | translate }}</th>
                 </tr>
                 <tr class="heading">
                     <td>{{ 'Bedded Pack Area' | translate }}</td>
                     <td class="text-center"> {{'< 65 sq ft (6m²) /' | translate }} {{ 'cow' | translate }}</td>
                     <td class="text-center">{{'65 sq ft (6m²) – 120 sq ft (11m²)/'  | translate }}{{ 'cow' | translate }}</td>
                     <td class="text-center">{{'> 120 sq ft (11m²) /' | translate  }} {{ 'cow' | translate }}</td>
                     <td></td>
                 </tr>
                 <tr v-for="group in groups" :key="'bedded_pack_' + group.getId()">
                     <td>{{ group.getName() }}</td>
                     <td class="slider-value">
                         <div v-if="getAssessorTypeDataField(group, 'lying_space') !== null"> 
                             <span v-if="(getAssessorTypeDataField(group, 'lying_space') / group.getGroupSize()) < convertTo(transitionConstants['lying_space_min'], 'm2')"
                               class="center red">X</span>
                         </div>           
                     </td>
                     <td class="slider-value">
                         <span v-if="(getAssessorTypeDataField(group, 'lying_space') / group.getGroupSize()) >= convertTo(transitionConstants['lying_space_min'], 'm2') &&
                             (getAssessorTypeDataField(group, 'lying_space') / group.getGroupSize()) <= convertTo(transitionConstants['lying_space_max'], 'm2')"
                               class="center yellow">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="(getAssessorTypeDataField(group, 'lying_space') / group.getGroupSize()) > convertTo(transitionConstants['lying_space_max'], 'm2')"
                               class="center green">X</span>
                     </td>
                     <td></td>
                 </tr>
                 <tr class="heading">
                     <td>{{ 'Drinking Space' | translate }}</td>
                     <td class="text-center">{{'< 3.2” (8cm)/' | translate }}{{ 'cow' | translate }}</td>
                     <td class="text-center">{{'3.2” - 4” (8-10cm)/' | translate }}{{ 'cow' | translate }}</td>
                     <td class="text-center">{{'> 4” (10cm)/' | translate}}{{ 'cow' | translate}}</td>
                     <td></td>
                 </tr>
                 <tr v-for="(group, index) in groups" :key="'dspc' + group.getId()">
                     <td>{{ group.getName() }}</td> 
 
                     <td class="slider-value">
                         <span v-if="parseFloat(getAssessorTypeDrinkingSpacePerCow(group)) <= convertTo(transitionConstants['feed_drink_space_min'], 'cm')"
                               class="center red large">{{ getAssessorTypeDrinkingSpacePerCow(group) + $t(' ' + convertUnit('cm') + '/cow') }}</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="parseFloat(getAssessorTypeDrinkingSpacePerCow(group)) > convertTo(transitionConstants['feed_drink_space_min'], 'cm') &&
                                     parseFloat(getAssessorTypeDrinkingSpacePerCow(group)) < convertTo(transitionConstants['feed_drink_space_max'], 'cm')"
                               class="center yellow large">{{ getAssessorTypeDrinkingSpacePerCow(group) + $t(' ' + convertUnit('cm') + '/cow') }} </span>
                     </td>
                     <td class="slider-value">
                         <span v-if="parseFloat(getAssessorTypeDrinkingSpacePerCow(group)) >= convertTo(transitionConstants['feed_drink_space_max'], 'cm')"
                               class="center green large">{{ getAssessorTypeDrinkingSpacePerCow(group) + $t(' ' + convertUnit('cm') + '/cow') }}</span>
                     </td>
                     <td v-if="index === 0" :rowspan="groups.length"></td>
                 </tr>
 
                 <tr class="heading">
                     <td>{{ 'Feeding Space' | translate }}</td>
                     <td class="text-center">
                         {{'≤ 18" (46 cm)/' | translate }} {{ 'cow' | translate }}
                     </td>
                     <td class="text-center">{{'>18” (46 cm)/' |  translate }}{{ 'cow' | translate }} {{' – <30” (75 cm)/' | translate}}{{ 'cow' | translate }}</td>
                     <td class="text-center">{{'≥30" (75cm)/' |  translate }}{{ 'cow' | translate}}</td>
                     <td></td>
                 </tr>
                 <tr v-for="(group, index) in groups" :key="'tfs' + group.getId()">
                     <td>{{ group.getName() }}</td>
 
                     <td class="slider-value">
                         <span v-if="parseFloat(getAssessorTypeFeedingSpacePerCow(group)) <= convertTo(transitionConstants['feed_bunk_space_min'], 'cm')"
                               class="center red large">{{ parseFloat(getAssessorTypeFeedingSpacePerCow(group)) + $t(' ' + convertUnit('cm') + '/cow') }}</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="parseFloat(getAssessorTypeFeedingSpacePerCow(group)) > convertTo(transitionConstants['feed_bunk_space_min'], 'cm') &&
                                     parseFloat(getAssessorTypeFeedingSpacePerCow(group)) < convertTo(transitionConstants['feed_bunk_space_max'], 'cm')"
                               class="center yellow large">{{ parseFloat(getAssessorTypeFeedingSpacePerCow(group)) + $t(' ' + convertUnit('cm') + '/cow') }} </span>
                     </td>
                     <td class="slider-value">
                         <span v-if="parseFloat(getAssessorTypeFeedingSpacePerCow(group)) >= convertTo(transitionConstants['feed_bunk_space_max'], 'cm')"
                               class="center green large">{{ parseFloat(getAssessorTypeFeedingSpacePerCow(group)) + $t(' ' + convertUnit('cm') + '/cow') }}</span>
                     </td>
                     <td v-if="index === 0" :rowspan="groups.length"></td>
                 </tr>
 
                 <tr class="heading">
                     <td>{{ 'Feeding Space Divider Type' | translate }}</td>
                     <td class="text-center">{{ 'Headlocks' | translate}}</td>
                     <td class="text-center">{{ 'Slant Bar Dividers' | translate }}</td>
                     <td class="text-center">{{ 'Feed Rail' | translate }}</td>
                     <td></td>
                 </tr>
                 <tr v-for="group in groups" :key="'fsdt' + group.getId()">
                     <td>{{ group.getName() }}</td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'bunk_divider_type') === transitionConstants['bunk_divider_type_max']"
                               class="center red">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'bunk_divider_type') === transitionConstants['bunk_divider_type_mid']"
                         class="center yellow">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'bunk_divider_type') === transitionConstants['bunk_divider_type_min']"
                               class="center green">X</span>
                     </td>
                     <td></td>
                 </tr>
 
                 <template v-if="filterGroupsByType('close-up', groups).length">
                     <tr class="heading" v-if="filterGroupsByFieldValue(groups, '19','is_maternity_pen', 'yes').length">
                         <td>{{ 'Maternity Undisturbed?' | translate }}</td>
                         <td class="text-center">{{ 'No' | translate }}</td>
                         <td class="text-center"></td>
                         <td class="text-center">{{ 'Yes' | translate }}</td>
                         <td></td>
                     </tr>
                     <tr v-for="(group) in filterGroupsByFieldValue(groups, '19','is_maternity_pen', 'yes')" :key="'maternityu' + group.getId()">
                         <td>{{ group.getName() }}</td>
                         <td class="slider-value">
                             <span v-if="getAssessorField(group.group, '19', 'maternity_undisturbed') === 'no'"
                                 class="center red">X</span>
                         </td>
                         <td class="slider-value"></td>
                         <td class="slider-value">
                             <span v-if="getAssessorField(group.group, '19', 'maternity_undisturbed') === 'yes'"
                                 class="center green">X</span>
                         </td>
                         <td></td>
                     </tr>
 
                     <tr class="heading" v-if="filterGroupsByFieldValue(groups, '19','is_maternity_pen', 'yes').length">
                         <td>{{ 'Are Cows Hydrated Immediately Post Calving?' | translate }}</td>
                         <td class="text-center">{{ 'No' | translate }}</td>
                         <td class="text-center"></td>
                         <td class="text-center">{{ 'Yes' | translate }}</td>
                         <td></td>
                     </tr>
                     <tr v-for="(group) in filterGroupsByFieldValue(groups, '19','is_maternity_pen', 'yes')" :key="'achipc' + group.getId()">
                         <td>{{ group.getName()}}</td>
                         <td class="slider-value">
                             <span v-if="getAssessorField(group.group, '19', 'cows_hydrated_immediately') === 'no'"
                                 class="center red">X</span>
                         </td>
                         <td class="slider-value"></td>
                         <td class="slider-value">
                             <span v-if="getAssessorField(group.group, '19', 'cows_hydrated_immediately') === 'yes'"
                                 class="center green">X</span>
                         </td>
                         <td></td>
                     </tr>
 
                     <tr class="heading" v-if="filterGroupsByFieldValue(groups, '19','is_maternity_pen', 'yes').length">
                         <td>{{ 'Feed Available in Maternity Pen?' | translate }}</td>
                         <td class="text-center">{{ 'No' | translate }}</td>
                         <td class="text-center"></td>
                         <td class="text-center">{{ 'Yes' | translate }}</td>
                         <td></td>
                     </tr>
                     <tr v-for="(group) in filterGroupsByFieldValue(groups, '19','is_maternity_pen', 'yes')" :key="'faimp' + group.getId()">
                         <td>{{ group.getName() }}</td>
                         <td class="slider-value">
                             <span v-if="getAssessorField(group.group, '19', 'is_feed_available') === 'no'"
                                 class="center red">X</span>
                         </td>
                         <td class="slider-value"></td>
                         <td class="slider-value">
                             <span v-if="getAssessorField(group.group, '19', 'is_feed_available') === 'yes'"
                                 class="center green">X</span>
                         </td>
                         <td></td>
                     </tr>
                 </template>
 
                 <tr class="heading">
                     <td>{{ 'Freestall Surface Cushion' | translate }}</td>
                     <td class="text-center">{{ 'Packed or Firm Bed' | translate }}</td>
                     <td class="text-center">{{ 'Cushioned Mattress with Thin Bedding' | translate }}</td>
                     <td class="text-center">{{ 'Deep Loose Bedding >3\"/8 cm Depth' | translate }}</td>
                     <td></td>
                 </tr>
                 <tr v-for="group in groups" :key="group.id">
                     <td>{{ group.getName() }}</td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'freestall_surface_cushion') === transitionConstants['freestall_surface_cushion_min']"
                               class="center red">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'freestall_surface_cushion') === transitionConstants['freestall_surface_cushion_mid']"
                               class="center yellow">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'freestall_surface_cushion') === transitionConstants['freestall_surface_cushion_max']"
                               class="center green">X</span>
                     </td>
                     <td></td>
                 </tr>
 
                 <tr class="heading">
                     <td>{{ 'Freestall Occupation Area' | translate }}</td>
                     <td class="text-center">{{ 'Significant Overlap in Imprint Area (>3"/8 cm)' | translate }}</td>
                     <td class="text-center">{{ 'Small Overlap in Imprint Area (1-3"/3-8 cm)' | translate }}</td>
                     <td class="text-center">{{ 'No Overlap of Imprint Area with Neighbor' | translate }}</td>
                     <td></td>
                 </tr>
 
                 <tr
                     v-for="group in groups"
                     :key="group.id"
                 >
                     <td>{{ group.getName() }}</td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'freestall_occupation_area') === transitionConstants['freestall_occupation_area_mid']"
                               class="center red">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'freestall_occupation_area') === transitionConstants['freestall_occupation_area_min']"
                               class="center yellow">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'freestall_occupation_area') === transitionConstants['freestall_occupation_area_max']"
                               class="center green">X</span>
                     </td>
                     <td></td>
                 </tr>
 
                 <tr class="heading">
                     <td>{{ 'Freestall Lunge Obstruction' | translate }}</td>
                     <td class="text-center">{{ 'Front and Side Lunge Obstruction' | translate }}</td>
                     <td class="text-center">{{ 'Front Lunge Obstruction Only' | translate }}</td>
                     <td class="text-center">{{ 'No Front or Side Lunge Obstruction' | translate }}</td>
                     <td></td>
                 </tr>
 
                 <tr v-for="group in groups" :key="group.id">
                     <td>{{ group.getName() }}</td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'freestalls_lunge_obstruction') === transitionConstants['freestalls_lunge_obstruction_min']"
                               class="center red">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'freestalls_lunge_obstruction') === transitionConstants['freestalls_lunge_obstruction_mid']"
                               class="center yellow">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'freestalls_lunge_obstruction') === transitionConstants['freestalls_lunge_obstruction_max']"
                               class="center green">X</span>
                     </td>
                     <td></td>
                 </tr>
 
                 <tr class="heading">
                     <td>{{ 'Heat Abatement - Freestall' | translate }}</td>
                     <td class="text-center">{{ 'No Fans or Water Cooling' | translate }}</td>
                     <td class="text-center">{{ 'Fans Over Stalls, No Water Cooling' | translate }}</td>
                     <td class="text-center">{{ 'Fans Over Freestalls, Water Cooling' | translate }}</td>
                     <td></td>
                 </tr>
                 <tr v-for="group in groups" :key="'ha-f' + group.getId()">
                     <td>{{ group.getName() }}</td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'heat_abatement') === transitionConstants['heat_abatement_min']"
                               class="center red">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'heat_abatement') === transitionConstants['heat_abatement_mid']"
                               class="center yellow">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'heat_abatement') === transitionConstants['heat_abatement_max']"
                               class="center green">X</span>
                     </td>
                     <td></td>
                 </tr>
 
                 <tr class="heading">
                     <td>{{ 'Avg Air Speed at Cow Level' | translate }}</td>
                     <td class="text-center">
                         {{ 'No ventilation available or' | translate }}&nbsp;
                         {{ comparativeValues.airSpeedAllCows('less-5', evaluation.data.measurements, $t) | translate }}
                     </td>
                     <td class="text-center">
                         {{ comparativeValues.airSpeedAllCows('5-8', evaluation.data.measurements, $t) | translate }}
                     </td>
                     <td class="text-center">
                         {{ comparativeValues.airSpeedAllCows('more-5', evaluation.data.measurements, $t) | translate }}
                     </td>
                     <td></td>
                 </tr>
 
                 <tr
                     v-for="group in groups"
                     :key="'aasatcl' + group.getId()"
                 >
                     <td>{{ group.getName() }}</td>
                     <td class="slider-value">
                         <span
                             v-if="getAssessorTypeDataField(group, 'air_speed_all_cows') === transitionConstants['air_speed_all_cows_min']
                                 || getAssessorTypeDataField(group, 'air_speed_all_cows') === 'no-vent'"
                             class="center red"
                         >
                             X
                         </span>
                     </td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'air_speed_all_cows') === transitionConstants['air_speed_all_cows_mid']"
                               class="center yellow">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'air_speed_all_cows') === transitionConstants['air_speed_all_cows_max']"
                               class="center green">X</span>
                     </td>
                     <td></td>
                 </tr>
 
                 <tr class="heading">
                     <td>{{ 'Avg Air Volume per Cow' | translate }}</td>
                     <td class="text-center">
                         {{ 'No ventilation available or' | translate }}&nbsp;
                         {{ comparativeValues.airSpeedCow('less-300', evaluation.data.measurements, $t) | translate }}
                     </td>
                     <td class="text-center">
                         {{ comparativeValues.airSpeedCow('300-1000', evaluation.data.measurements, $t) | translate }}
                     </td>
                     <td class="text-center">
                         {{ comparativeValues.airSpeedCow('more-1000', evaluation.data.measurements, $t) | translate }}
                     </td>
                     <td></td>
                 </tr>
 
                 <tr v-for="group in groups" :key="'aavpc' + group.getId()">
                     <td>{{ group.getName() }}</td>
                     <td class="slider-value">
                         <span
                             v-if="getAssessorTypeDataField(group, 'air_speed_cow') === transitionConstants['air_speed_cow_min']
                                 || getAssessorTypeDataField(group, 'air_speed_cow') === 'no-vent'"
                             class="center red"
                         >X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'air_speed_cow') === transitionConstants['air_speed_cow_mid']"
                               class="center yellow">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'air_speed_cow') === transitionConstants['air_speed_cow_max']"
                               class="center green">X</span>
                     </td>
                     <td></td>
                 </tr>
 
                 <tr class="heading">
                     <td>{{ 'Are Close-Up Heifers Mixed with Cows?' | translate }}</td>
                     <td class="text-center">{{ 'Yes' | translate }}</td>
                     <td class="text-center"></td>
                     <td class="text-center">{{ 'No' | translate }}</td>
                     <td></td>
                 </tr>
                 <tr>
                     <td></td>
                     <td class="slider-value">
                         <span v-if="evaluation.data.close_up_heifers_mixed_with_cows === 'yes'"
                               class="center red">X</span>
                     </td>
                     <td class="slider-value"></td>
                     <td class="slider-value">
                         <span v-if="evaluation.data.close_up_heifers_mixed_with_cows === 'no'"
                               class="center green">X</span>
                     </td>
                     <td></td>
                 </tr>
 
                 <tr class="heading">
                     <td>{{ 'Frequency Animals are Moved to Close-Up Pen' | translate }}</td>
                     <td class="text-center">{{ 'Multiple Times per Week' | translate }}</td>
                     <td class="text-center">{{ 'Weekly Filling of Group Pen' | translate }}</td>
                     <td class="text-center">{{ 'Cows Remain With Same Group of Cows >14 Days' | translate }}</td>
                     <td></td>
                 </tr>
                 <tr>
                     <td>{{ 'Pen move(s) into close-up' | translate }}</td>
                     <td class="slider-value">
                         <span v-if="evaluation.data.how_many_moves_into_close_up_pen === transitionConstants['how_many_moves_into_close_up_pen_min']"
                               class="center red">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="evaluation.data.how_many_moves_into_close_up_pen === transitionConstants['how_many_moves_into_close_up_pen_mid']"
                               class="center yellow">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="evaluation.data.how_many_moves_into_close_up_pen === transitionConstants['how_many_moves_into_close_up_pen_max']"
                               class="center green">X</span>
                     </td>
                     <td></td>
                 </tr>
 
                 <template v-if="evaluation.data.move_into_calving_pen">
                     <tr class="heading">
                         <td>{{ 'When are Cows Moved into Calving Pen (If Separate from Close-Up)?' | translate }}</td>
                         <td class="text-center">{{ '3-7 Days Before Due Date' | translate }}</td>
                         <td class="text-center"></td>
                         <td class="text-center">
                             {{ '< 2 Days or > 7 Days Before Due Date' | translate }}
                         </td>
                         <td></td>
                     </tr>
 
                     <tr>
                         <td>{{ 'Entry into calving pens' | translate }}</td>
                         <td class="slider-value">
                             <span v-if="evaluation.data.move_into_calving_pen === transitionConstants['move_into_calving_pen_min']"
                                 class="center red">X</span>
                         </td>
                         <td class="slider-value">
                             <span v-if="evaluation.data.move_into_calving_pen === transitionConstants['move_into_calving_pen_mid']"
                                 class="center yellow">X</span>
                         </td>
                         <td class="slider-value">
                             <span v-if="evaluation.data.move_into_calving_pen === transitionConstants['move_into_calving_pen_max']"
                                 class="center green">X</span>
                         </td>
                         <td></td>
                     </tr>
                 </template>
 
                 <tr class="heading">
                     <td>{{ 'How are Sick Cows Managed?' | translate }}</td>
                     <td class="text-center">{{ 'Mixed at All Times' | translate }}</td>
                     <td class="text-center">{{ 'Segregated at Milking' | translate }}</td>
                     <td class="text-center">{{ 'Segregated at All Times' | translate }}</td>
                     <td></td>
                 </tr>
                 <tr>
                     <td> </td>
                     <td class="slider-value">
                         <span v-if="evaluation.data.how_are_sick_cows_managed === transitionConstants['how_are_sick_cows_managed_min']"
                               class="center red">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="evaluation.data.how_are_sick_cows_managed === transitionConstants['how_are_sick_cows_managed_mid']"
                               class="center yellow">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="evaluation.data.how_are_sick_cows_managed === transitionConstants['how_are_sick_cows_managed_max']"
                               class="center green">X</span>
                     </td>
                     <td></td>
                 </tr>
 
                 <tr class="heading">
                     <td>{{ 'Dry Off Protocol' | translate }}</td>
                     <td class="text-center">{{ 'Daily' | translate }}</td>
                     <td class="text-center">{{ 'Multiple Times per Week' | translate }}</td>
                     <td class="text-center">{{ 'Weekly' | translate }}</td>
                     <td></td>
                 </tr>
                 <tr>
                     <td>{{ 'Frequency' | translate }}</td>
                     <td class="slider-value">
                         <span v-if="evaluation.data.dry_off_protocol === transitionConstants['dry_off_protocol_min']"
                               class="center red">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="evaluation.data.dry_off_protocol === transitionConstants['dry_off_protocol_mid']"
                               class="center yellow">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="evaluation.data.dry_off_protocol === transitionConstants['dry_off_protocol_max']"
                               class="center green">X</span>
                     </td>
                     <td></td>
                 </tr>
             </table>
 
             <h2 class="h2 d-inline-block mt-4">{{ 'Feeding & Body Condition' | translate }}</h2>
             <table class="table primary table-5-col">
                 <tr>
                     <th scope="col" class="text-left col-width">{{ 'Estimated Risk' | translate }}</th>
                     <th scope="col" class="col-width">{{ 'High Risk' | translate }}</th>
                     <th scope="col" class="col-width">{{ 'Moderate Risk' | translate }}</th>
                     <th scope="col" class="col-width">{{ 'Low Risk' | translate }}</th>
                 </tr>
 
                 <tr class="heading" v-if="filterGroupsByType('fresh', groups).length">
                     <td>
                         {{ '% Cows Rumen Fill < 3' | translate }}
                     </td>
                     <td class="text-center">> 25%</td>
                     <td class="text-center">15-25%</td>
                     <td class="text-center">&lt;&nbsp;15%</td>
                 </tr>
                 <template v-for="group in filterGroupsByType('fresh', groups)">
                     <tr :key="'rf3' + group.getId()">
                         <td>{{ group.getName() }}</td>
                         <td class="slider-value">
                             <span v-if="parseFloat(getAssessorField(group.group, '19', 'cows_rumen_fill')) > transitionConstants['cows_rumen_fill_max']"
                                 class="center red">X</span>
                         </td>
                         <td class="slider-value">
                             <span v-if="parseFloat(getAssessorField(group.group, '19', 'cows_rumen_fill')) <= transitionConstants['cows_rumen_fill_max'] &&
                                         parseFloat(getAssessorField(group.group, '19', 'cows_rumen_fill')) >= transitionConstants['cows_rumen_fill_min']"
                                 class="center yellow">X</span>
                         </td>
                         <td class="slider-value">
                             <span v-if="parseFloat(getAssessorField(group.group, '19', 'cows_rumen_fill')) < transitionConstants['cows_rumen_fill_min']"
                                 class="center green">X</span>
                         </td>
                     </tr>
                 </template>
 
                 <tr class="heading" v-if="groups.length - filterGroupsByType('fresh', groups).length">
                     <td>
                         {{ '% Cows Rumen Fill < 4' | translate }}
                     </td>
                     <td class="text-center">> 25%</td>
                     <td class="text-center">15-25%</td>
                     <td class="text-center">&lt;&nbsp;15%</td>
                 </tr>
                 <template v-for="group in groups">
                     <tr v-if="group.getTypeOfAnimal() !== 'fresh'" :key="'rf4' + group.getId()">
                         <td>{{ group.getName() }}</td>
                         <td class="slider-value">
                             <span v-if="parseFloat(getAssessorTypeDataField(group, 'cows_rumen_fill')) > transitionConstants['cows_rumen_fill_max']"
                                 class="center red">X</span>
                         </td>
                         <td class="slider-value">
                             <span v-if="parseFloat(getAssessorTypeDataField(group, 'cows_rumen_fill')) <= transitionConstants['cows_rumen_fill_max'] &&
                                         parseFloat(getAssessorTypeDataField(group, 'cows_rumen_fill')) >= transitionConstants['cows_rumen_fill_min']"
                                 class="center yellow">X</span>
                         </td>
                         <td class="slider-value">
                             <span v-if="parseFloat(getAssessorTypeDataField(group, 'cows_rumen_fill')) < transitionConstants['cows_rumen_fill_min']"
                                 class="center green">X</span>
                         </td>
                     </tr>
                 </template>
 
                 <tr class="heading">
                     <td>
                         {{ '% of Cows with Manure Score < 3' | translate }}
                     </td>
                     <td class="text-center">> 25%</td>
                     <td class="text-center">15-25%</td>
                     <td class="text-center">&lt;&nbsp;15%</td>
                 </tr>
                 <template v-for="group in groups">
                     <tr :key="'cwms3' + group.getId()">
                         <td>{{ group.getName() }}</td>
                         <td class="slider-value">
                             <span v-if="parseFloat(getAssessorTypeDataField(group, 'cows_manure_score')) > transitionConstants['cows_manure_score_max']"
                                 class="center red">X</span>
                         </td>
                         <td class="slider-value">
                             <span v-if="parseFloat(getAssessorTypeDataField(group, 'cows_manure_score')) <= transitionConstants['cows_manure_score_max'] &&
                                         parseFloat(getAssessorTypeDataField(group, 'cows_manure_score')) >= transitionConstants['cows_manure_score_min']"
                                 class="center yellow">X</span>
                         </td>
                         <td class="slider-value">
                             <span v-if="parseFloat(getAssessorTypeDataField(group, 'cows_manure_score')) < transitionConstants['cows_manure_score_min']"
                                 class="center green">X</span>
                         </td>
                     </tr>
                 </template>
 
                 <tr class="heading">
                     <td>{{ '% of Cows with BCS ≤2.5 or ≥3.75, 1st lact' | translate }}</td>
                     <td class="text-center">>{{ transitionConstants['body_condition_score_1_max']}}%</td>
                     <td class="text-center">30-10%</td>
                     <td class="text-center">&lt;{{ transitionConstants['body_condition_score_1_min']}}%</td>
                 </tr>
                 <tr
                     v-for="group in groups"
                     :key="group.id"
                 >
                     <td>{{ group.getName() }}</td>
                     <td class="slider-value">
                         <span v-if="parseFloat(getAssessorTypeDataField(group, 'body_condition_score_1')) > transitionConstants['body_condition_score_1_max']"
                               class="center red">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="parseFloat(getAssessorTypeDataField(group, 'body_condition_score_1')) <= transitionConstants['body_condition_score_1_max'] &&
                                     parseFloat(getAssessorTypeDataField(group, 'body_condition_score_1')) >= transitionConstants['body_condition_score_1_min']"
                               class="center yellow">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="parseFloat(getAssessorTypeDataField(group, 'body_condition_score_1')) < transitionConstants['body_condition_score_1_min']"
                               class="center green">X</span>
                     </td>
                 </tr>
                 <tr class="heading">
                     <td>{{ '% of Cows with BCS ≤2.5 or ≥3.75, ≥2nd lact' | translate }}</td>
                     <td class="text-center">>{{ transitionConstants['body_condition_score_2_max']}}%</td>
                     <td class="text-center">30-10%</td>
                     <td class="text-center">&lt;{{ transitionConstants['body_condition_score_2_min']}}%</td>
                 </tr>
                 <tr v-for="group in groups" :key="group.id">
                     <td>{{ group.getName() }}</td>
 
                     <td class="slider-value">
                         <span v-if="parseFloat(getAssessorTypeDataField(group, 'body_condition_score_2')) > transitionConstants['body_condition_score_2_max']"
                               class="center red">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="parseFloat(getAssessorTypeDataField(group, 'body_condition_score_2')) <= transitionConstants['body_condition_score_2_max'] &&
                                     parseFloat(getAssessorTypeDataField(group, 'body_condition_score_2')) >= transitionConstants['body_condition_score_2_min']"
                               class="center yellow">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="parseFloat(getAssessorTypeDataField(group, 'body_condition_score_2')) < transitionConstants['body_condition_score_2_min']"
                               class="center green">X</span>
                     </td>
                 </tr>
 
                 <tr class="heading">
                     <td>{{ 'Refusal %' | translate }}</td>
                     <td class="text-center">&lt;&nbsp;3%</td>
                     <td class="text-center">3-5%</td>
                     <td class="text-center">&gt;&nbsp;5%</td>
                 </tr>
                 <template v-for="group in groups">
                     <tr :key="'refusal' + group.getId()">
                         <td>{{ group.getName() }}</td>
                         <td class="slider-value">
                             <span v-if="parseFloat(getAssessorTypeDataField(group, 'refusal')) < transitionConstants['refusal_min']"
                                 class="center red">X</span>
                         </td>
                         <td class="slider-value">
                             <span v-if="parseFloat(getAssessorTypeDataField(group, 'refusal')) >= transitionConstants['refusal_min'] &&
                                         parseFloat(getAssessorTypeDataField(group, 'refusal')) < transitionConstants['refusal_max']"
                                 class="center yellow">X</span>
                         </td>
                         <td class="slider-value">
                             <span v-if="parseFloat(getAssessorTypeDataField(group, 'refusal')) >= transitionConstants['refusal_max']"
                                 class="center green">X</span>
                         </td>
                     </tr>
                 </template>
 
                 <tr class="heading">
                     <td>{{ 'TMR Sorting Difference (% Points)' | translate }}</td>
                     <td class="text-center">≥10</td>
                     <td class="text-center">5-9</td>
                     <td class="text-center">≤5</td>
                 </tr>
                 <tr
                     v-for="group in groups"
                     :key="group.id"
                 >
                     <td>{{ group.getName() }}</td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'tmr_sorting') === transitionConstants['tmr_sorting_min']"
                               class="center red">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'tmr_sorting') === transitionConstants['tmr_sorting_mid']"
                               class="center yellow">X</span>
                     </td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'tmr_sorting') === transitionConstants['tmr_sorting_max']"
                               class="center green">X</span>
                     </td>
                 </tr>
 
                 <tr>
                     <td colspan="5" class="p-0">
                         <table class="table mb-0">
                             <tr class="heading" v-if="filterGroupsByType('fresh', groups).length">
                                 <td class="w-25">{{ 'Screening Method Used' | translate }}</td>
                                 <td class="text-center w-20">{{ 'No Program' | translate }}</td>
                                 <td class="text-center w-20">{{ 'Routine Temping, Multiple Staff' | translate }}</td>
                                 <td class="text-center w-20">{{ 'Daily Milk wt, Rumination Activity' | translate }}</td>
                                 <td class="text-center w-20">{{ 'Dedicated Staff, Appetite, Front & Rear Visual Exam' | translate }}</td>
                             </tr>
                             <tr v-for="group in filterGroupsByType('fresh', groups)" :key="'smu' + group.getId()">
                                 <td>{{ group.getName() }}</td>
                                 <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'screening_method') === transitionConstants['screening_method_min']"
                               class="center red">X</span>
                                 </td>
                                 <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'screening_method') === transitionConstants['screening_method_mid']"
                               class="center yellow">X</span>
                                 </td>
                                 <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'screening_method') === transitionConstants['screening_method_mid2']"
                               class="center yellow">X</span>
                                 </td>
                                 <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'screening_method') === transitionConstants['screening_method_max']"
                               class="center green">X</span>
                                 </td>
                             </tr>
                         </table>
                     </td>
                 </tr>
                 <tr>
                     <td colspan="5" class="p-0">
                         <table class="table mb-0">
                             <tr class="heading" v-if="filterGroupsByType('fresh', groups).length">
                                 <td class="w-25">{{ 'Time Spent Checking Fresh Cows (hr)' | translate }}</td>
                                 <td class="text-center w-20">{{ '≥3 hrs' | translate }}</td>
                                 <td class="text-center w-20">{{ '1-3 hrs' | translate }}</td>
                                 <td class="text-center w-20">{{ '≤1 hr' | translate }}</td>
                             </tr>
                             <tr v-for="(group, index) in filterGroupsByType('fresh', groups)" :key="'tcfc-hr' + index">
                                 <td>{{ group.getName() }}</td>
                                 <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'time_checking_hr') === transitionConstants['time_checking_hr_min']"
                               class="center red">X</span>
                                 </td>
                                 <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'time_checking_hr') === transitionConstants['time_checking_hr_mid']"
                               class="center yellow">X</span>
                                 </td>
                                 <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'time_checking_hr') === transitionConstants['time_checking_hr_max']"
                               class="center green">X</span>
                                 </td>
                             </tr>
                         </table>
                     </td>
                 </tr>
                 
                 <tr class="heading" v-if="filterGroupsByType('fresh', groups).length">
                     <td>{{ 'Feed Available after Milking?' | translate }}</td>
                     <td class="text-center">{{ 'No' | translate }}</td>
                     <td></td>
                     <td class="text-center">{{ 'Yes' | translate }}</td>
                 </tr>
                 <tr v-for="group in filterGroupsByType('fresh', groups)" :key="'faamilking' + group.getId()">
                     <td>{{ group.getName() }}</td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'is_feed_available_af_milking') === 'no'"
                               class="center red">X</span>
                     </td>
                     <td class="slider-value"></td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'is_feed_available_af_milking') === 'yes'"
                               class="center green">X</span>
                     </td>
                 </tr>
 
                 <tr class="heading" v-if="filterGroupsByType('fresh', groups).length">
                     <td>{{ 'Checks Done After Milking?' | translate }}</td>
                     <td class="text-center">{{ 'No' | translate }}</td>
                     <td></td>
                     <td class="text-center">{{ 'Yes' | translate }}</td>
                 </tr>
                 <tr v-for="group in filterGroupsByType('fresh', groups)" :key="'cdam' + group.getId()">
                     <td>{{ group.getName() }}</td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'checks_af_milking') === 'no'"
                               class="center red">X</span>
                     </td>
                     <td class="slider-value"></td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'checks_af_milking') === 'yes'"
                               class="center green">X</span>
                     </td>
                 </tr>
 
                 <tr class="heading" v-if="filterGroupsByType('fresh', groups).length">
                     <td>{{ 'Check Appetite?' | translate }}</td>
                     <td class="text-center">{{ 'No' | translate }}</td>
                     <td></td>
                     <td class="text-center">{{ 'Yes' | translate }}</td>
                 </tr>
                 <tr v-for="(group) in filterGroupsByType('fresh', groups)" :key="'capetite' + group.getId()">
                     <td>{{ group.getName() }}</td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'is_appetite_checked') === 'no'"
                               class="center red">X</span>
                     </td>
                     <td class="slider-value"></td>
                     <td class="slider-value">
                         <span v-if="getAssessorTypeDataField(group, 'is_appetite_checked') === 'yes'"
                               class="center green">X</span>
                     </td>
                 </tr>
 
 
             </table>
         </div>
 
         <div class="row misc-bottom mt-4">
             <div class="col-md-12 flex-column flex-lg-row justify-content-md-between justify-content-lg-start d-flex">
                 <a class="btn btn-secondary btn-full--sm mb-2 mt-md-0" href="javascript:history.go(-1)">{{ "Back" | translate }}</a>
                 <router-link
                         :to="'/users/' + $route.params.userId + '/evaluations/' + $route.params.evaluationId + '/groups/' + $route.params.groupId + '/assessors/' + $route.params.assessorType"
                         class="btn btn-primary btn-full--sm mb-2 mt-md-0 ml-lg-4">
                     {{"Setup" | translate}}
                 </router-link>
 
             </div>
              <div class="col-md-12 flex-column-reverse flex-lg-row-reverse justify-content-md-between justify-content-lg-start d-flex">
                 <router-link v-if="user && user.role !== 'data_entry'" :to="'/reports'" class="btn btn-primary btn-full--sm mb-2 mb-2 mt-md-0">
                     {{"Reports" | translate}}
                 </router-link>
                 <button v-else-if="user && user.role === 'data_entry' && !$store.state.offline" data-toggle="modal" @click="requestReport(evaluation.name)" :data-target="['#requestSent-' + user._id]" class="btn btn-primary btn-full--sm pull-right mb-2 mt-md-0">
                     {{ 'Request Report' | translate }}
                     <span v-show="$store.state.loader" class="loader ml-1"></span>
                 </button>
                 <router-link :to="{ name: 'Dashboard' }" class="btn btn-primary btn-full--sm mr-lg-4 mb-2"> <!-- b4dPotato not translated -->
                     {{ 'Dashboard' | translate }}
                 </router-link>
              </div>
         </div>
 
     </div>
 </template>
 
 <script>
 /** Classes */
 import Evaluation from '@/libs/Classes/Evaluation.js';
 import { comparativeValues, transitionConstants } from "@/libs/Classes/constants/transition";
 import requestReportMixin from '@/mixins/requestReport.js';
 /** Helpers */
 import convert from 'convert-units';
 
 /** Mixins */
 import { animalMixin } from '@/mixins/animal.mixin.js';
 import { mapState } from 'vuex';
 
 export default {
     mixins: [animalMixin,requestReportMixin],
     props: ['assessor', 'group', 'evaluation'],
 
     computed: {
       ...mapState({
         user: state => state.users.user
     }),
     },
 
     data() {
         return {
             currentPage: 'summary',
             isLoaded: false,
             groups: [],
             comparativeValues,
             transitionConstants,
         }
     },
 
     created() {
         const evaluationClass = new Evaluation(this, this.evaluation);
         const groups = evaluationClass.getGroups();
 
         for (let i = 0; i < groups.length; i++) {
             const transition = groups[i]
                 .getAssessors()
                 .find((assessor) => {
                     if (assessor) {
                         return assessor.getType() == '19';
                     }
             });
 
             if (transition !== undefined && transition.isStarted()) {
                 this.groups.push(groups[i]);
             }
 
             // console.log('this.groups', this.groups);
         }
     },
 
     methods: {
      
         /**
          * Get data field by assessor type
          * 
          * @param {Object} group Group Data
          * @param {String} dataField Name of data field
          * @param {String} assessorType Assessor type
          * 
          * @returns {String|Number} Data
          */
         getAssessorTypeDataField(group, dataField, assessorType = '19') {
             if (!dataField) throw Error('No data field.');
 
             return group
                 .getAssessorByType(assessorType)
                 .getDataField(dataField);
         },
 
 
         /**
          * Get drinking space per cow by assessor type
          * 
          * @param {Object} group Group Data
          * @param {String} assessorType Assessor type
          * 
          * @returns {String|Number} Drinking space per cow 
          */
         getAssessorTypeDrinkingSpacePerCow(group, assessorType = '19') {
             return group
                 .getAssessorByType(assessorType)
                 .getDrinkingSpacePerCow();
         },
 
 
         /**
          * Get feeding space per cow by assessor type
          * 
          * @param {Object} group Group Data
          * @param {String} assessorType Assessor type
          * 
          * @returns {String|Number} Feeding space per cow 
          */
         getAssessorTypeFeedingSpacePerCow(group, assessorType = '19') {
             return group
                 .getAssessorByType(assessorType)
                 .getFeedingSpacePerCow();
         },
 
 
         convertUnit(unit) {
             return this.$getEquivalentUnit(this.evaluation.data.measurements, unit);
         },
 
 
         convertTo (value, unitFrom) {
         // workaround for Feeding space constant
         // if (value === 75 && unitFrom === 'cm') return 30
         return parseFloat(convert(value)
             .from(unitFrom)
             .to(this.$getEquivalentUnit(this.evaluation.data.measurements, unitFrom)))
             .toFixed(2);
         },
 
 
         getSliderValuePosition (value, mode = 'ltr') {
             let min = transitionConstants[`${value}_min`],
                 max = transitionConstants[`${value}_max`];
 
             if (value === 'projected_first_test_305') {
                 min = parseInt(convert(transitionConstants['projected_first_test_305_min'])
                     .from('kg')
                     .to(this.$getEquivalentUnit(this.evaluation.data.measurements,'kg')))
                     .toDecimal(0);
                 max = parseInt(convert(transitionConstants['projected_first_test_305_max'])
                     .from('kg')
                     .to(this.$getEquivalentUnit(this.evaluation.data.measurements,'kg')))
                     .toDecimal(0);
             }
 
             // console.log({ value });
 
             if (['stillbirths_1', 'stillbirths_2'].includes(value)) {
                 max -=2
             }
             if (value === 'ketosis') {
                 min = 1
             }
             if (value === 'displaced_abomasum') {
                 min = -1
                 max = 9
             }
 
             let x = 0;
 
             if (value === 'projected_first_test_305') {
                 x = parseFloat(this.evaluation.data[value + '_' + this.$getEquivalentUnit(this.evaluation.data.measurements,'kg') ]);
             } else {
                 x = parseFloat(this.evaluation.data[value]);
             }
 
             let step = 0;
 
             if (mode === 'ltr') {
                 step = (max - min) / 100
                 if (x <= min) return 'calc(0% - 26px)'
                 if (x >= max) return 'calc(100% - 26px)'
                 return `calc(${(x - min) / step}% - 26px)`
             } else {
                 step = (max - min) / 100
                 if (x >= max) return 'calc(0% - 26px)'
                 if (x <= min ) return 'calc(100% - 26px)'
                 return `calc(${Math.abs(x - max) / step}% - 26px)`
             }
         },
 
 
         getSliderValueFill (value, mode = 'ltr') {
             let min = transitionConstants[`${value}_min`],
                 mid = transitionConstants[`${value}_mid`],
                 max = transitionConstants[`${value}_max`];
 
             if (value === 'projected_first_test_305') {
                 min = parseInt(convert(transitionConstants['projected_first_test_305_min'])
                 .from('kg')
                 .to(this.$getEquivalentUnit(this.evaluation.data.measurements,'kg')))
                 .toDecimal(0);
 
                 mid = parseInt(convert(transitionConstants['projected_first_test_305_mid'])
                 .from('kg')
                 .to(this.$getEquivalentUnit(this.evaluation.data.measurements,'kg')))
                 .toDecimal(0);
 
                 max = parseInt(convert(transitionConstants['projected_first_test_305_max'])
                 .from('kg')
                 .to(this.$getEquivalentUnit(this.evaluation.data.measurements,'kg')))
                 .toDecimal(0);
             }
 
             let x = 0;
 
             if (value === 'projected_first_test_305') {
                 x = parseFloat(this.evaluation.data[value + '_' + this.$getEquivalentUnit(this.evaluation.data.measurements,'kg') ]);
             } else {
                 x = parseFloat(this.evaluation.data[value]);
             }
 
             if (mode === 'ltr') {
                 if (x <= min) return 'red';
                 if (x > min && x <= mid) return 'yellow';
                 if (x > mid) return 'green';
             } else if (mode === 'rtl') {
                 if (x <= min) return 'green';
                 if (x > min && x <= mid) return 'yellow';
                 if (x > mid) return 'red';
             } else {
                 if (x <= transitionConstants[`${value}_green`]) return 'green';
                 if (x > transitionConstants[`${value}_yellow`]) return 'red';
                 return 'yellow';
             }
         },
 
         convert,
     },
 }
 </script>
 
 <style scoped lang="scss">
     .table {
         min-width: 900px;
         overflow: hidden;
         &-wrapper {
             overflow: scroll;
             position: relative;
             margin-left: 0;
             margin-right: 0;
             &:after {
                 content: '';
                 position: absolute;
                 right: 0;
 
             }
         }
         th {
             text-align: center;
             vertical-align: middle;
             padding: 6px 25px;
             font-size: 13px;
             &:not(:last-child) {
                 border-right: 1px solid #fff
             }
         }
         .index-col {
             width: 350px;
         }
 
         &.primary th {
             background-color: #0000FF;
             color: #FFFFFF;
         }
 
         .heading td {
             background-color: #e0e0e0;
             font-weight: 700;
             white-space: normal;
         }
 
         td {
             background-color: white;
             font-size: 13px;
             font-weight: 100;
             font-family: 'Arial', sans-serif;
             padding: 10px 26px;
 
             &:not(:last-child) {
                 border-right: 1px solid #f1f1f1
             }
 
             &.slider-value {
                 position: relative;
                 padding: 5px 26px;
                 vertical-align: middle;
 
                 span {
                     font-size: 13px;
                     background: red;
                     color: #fff;
                     display: inline-block;
                     position: relative;
                     padding: 4px 4px;
                     border-radius: 75px;
                     min-width: 52px;
                     text-align: center;
                     text-shadow: 1px 1px 5px rgba(0,0,0,0.3);
 
                     &.center {
                         display: block;
                         width: 52px;
                         margin: 0 auto;
                     }
 
                     &.red {
                         background-color: #ff0000
                     }
 
                     &.yellow {
                         background-color: #FFCC66
                     }
 
                     &.green {
                         background-color: #00cc00
                     }
 
                     &.large {
                         width: 82px;
                     }
                 }
             }
         }
     }
 
     .table-5-col {
         td, th {
             width: 300px;
         }
         .heading {
             td {
                 vertical-align: middle;
             }
         }
     }
 </style>
 
